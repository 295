import React, { useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player/lazy"

import LLPContactForm from "../../components/StudiLive Comps/LLPContactForm"
import { AlertMessage } from "../../components/alert"
import SEO from "../../components/seo"

import "../../css/LLP.css"
import Boy_01 from "../../images/studilive/Boy_01.png"
import Logo from "../../images/studilive/studilive.svg"
import Doubt_Clarification from "../../images/studilive/Doubt_Clarification.svg"
import FullAccesstoTataStudi from "../../images/studilive/FullAccesstoTataStudi.svg"
import ScientificProvenApproach from "../../images/studilive/ScientificProvenApproach.svg"
import IndividualAttention from "../../images/studilive/IndividualAttention.svg"

export default function LLPPage() {
  const [formName, setformName] = useState("")

  const handleMessage = value => {
    setformName(value)
  }

  let imageMap = [
    {
      icon : IndividualAttention,
      text: <>Small batch <br></br> size of 8-10 students</>
    },
    {
      icon : Doubt_Clarification,
      text: <>1- to-1 doubt <br></br> clarification sessions</>
    },
    {
      icon : ScientificProvenApproach,
      text: <>Scientific and proven <br></br> approach for teaching</>
    },
  {
    icon : FullAccesstoTataStudi,
    text: <>Full access to <br></br> Tata Studi learning app</>
   
  }
  ]
  // let imageMap1 = [
  //   Doubt_Clarification,
  //   FullAccesstoTataStudi,
  //   ScientificProvenApproach,
  //   IndividualAttention,
  // ]

  return (
    <div>
      <SEO
        title="Tata Studi: Class 1-10 Learning App for CBSE, NCERT"
        lang="en"
        description="Studi with Tata ClassEdge is a personalised e-Learning app for K-12 students of NCERT, CBSE, ICSE and State Boards. It’s based on principle of Science of Learning. Studi prepares students to be exam-ready through planning, concept videos and self-tests. It helps students plan, practice and perfect what they learn."
      />

      <div className="site_background_LLP">
        <h1 className="displayNone">TataClass</h1>
        <div className="container-fluid py-2">
          <p className="py-1 my-2 float-start  ">
            <Link to="/">
              <img src={Logo} className="logo_tatapage" alt="Tata Class Edge" />
            </Link>
          </p>
          {formName.length > 0 && (
            <div className="fixed-top">
              <AlertMessage
                variant="alert-danger"
                smallMsg="There was an error! Please check that you have filled all the details correctly!"
                classes="form-alert"
              />
            </div>
          )}

          <br />
          <div className="row text-center mx-sm-1 mx-0 my-0 d-md-none d-block">
            <span style={{ color: "black" }}>
              <h3>Available for classes 8 - 10 for CBSE curriculum</h3>
            </span>
          </div>
          <br />
          <div className="row mt-md-n5">
            <div className="col-sm-12 col-md-6 col-xl-4 px-0 text-center order-md-1 order-last">
              <img
                src={Boy_01}
                alt="Tata Class Edge"
                width="100%"
                height="100%"
                className="img-fluid boy"
              />
            </div>

            <div className="col-sm-12 col-md-6 col-xl-4 my-5 my-sm-0 order-xl-2">
              <div className="learning_layout feature-icons">
                <h1 className="learning_title_LLP mt-n5 py-1">
                  <p className="mt-1 font-lato">Live Online Classes</p>
                </h1>
                <div className="row">
                  {imageMap.map((item, idx) => {
                    return (
                      <div
                        className="col-xl-12 d-flex flex-column mx-2"
                        key={idx}
                      >
                        <span className="tooltip_learn1">
                          <img
                            src={item.icon}
                            alt="alt text"
                            className="btnimg_LLP"
                            width="54px"
                            height="54px"
                          />
                          <span>
                            {item.text}
                          </span>
                        </span>
                        {idx != 4 ? (
                          <hr className="horizontalLine"></hr>
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  })}
                  <div className="col-xl-12 d-flex flex-column mx-2">
                      <span className="tooltip_learn1">
                        
                        <span>
                        Courses starting at  Rs.3833 per month*
                        </span>
                      </span>
                      
                    </div>
                </div>
              </div>
              <br />
              <div className="col-xs-12 col-md-10 mx-auto justify-content-center">
                <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer
                    className="react-player"
                    url="https://youtu.be/2vk7x95Jkgk"
                    width="100%"
                    height="100%"
                    controls
                    playing
                    light="https://img.youtube.com/vi/2vk7x95Jkgk/maxresdefault.jpg"
                  />
                </div>
                <br />
              </div>
            </div>

            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-4 order-xl-3 order-first my-md-5 my-xl-0 mt-0">
              <div className="mt-md-n5">
                <LLPContactForm
                  message={handleMessage}
                  // error={handleError}
                />
              </div>
            </div>
          </div>
          <div className="row text-center mx-sm-1 mx-0 my-2 d-md-block d-none">
            <span style={{ color: "black" }}>
              <h3>Online Classes for CBSE 8th -10th, Mah board – 10th</h3>
              <h5><i>*Conditions apply</i></h5>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
